import {BriefPanelName, PanelTypeEnum, Unit, VAxisType} from '@balrog/component/common';
import {ProfileInterface} from './interface/profile.interface';
import { AccessPermissionEnum } from '@balrog/core/model/const';

export class WindTurbineControlGondolaProfile implements ProfileInterface {
    statuses = {
        WTC_STAT_HAMULEC_MECHANICZNY_BLOKADA: {title: 'Hamulec mechaniczy hamowanie'},
        WTC_STAT_HAMULEC_MECHANICZNY_ODBLOKOWANIE: {title: 'Hamulec mechaniczy odpuszczanie'},
        WTC_STAT_HAMULEC_FAZOWY: {title: 'Hamulec fazowy'},
        WTC_STAT_PRZEKAZNIK_RL1: {title: 'Styk RL1'},
        WTC_STAT_PRZEKAZNIK_RL2: {title: 'Styk RL2'},
        WTC_STAT_PRZEKAZNIK_RL3: {title: 'Styk RL3'},
        WTC_STAT_PRZEKAZNIK_RL4: {title: 'Styk RL4'},
        WTC_STAT_PRZEKAZNIK_RL5: {title: 'Styk RL5'},
        WTC_STAT_GONDOLA_LEWO: {title: 'Obrót turbiny w lewo'},
        WTC_STAT_GONDOLA_PRAWO: {title: 'Obrót turbiny w prawo'},
        WTC_STAT_REZYSTORY: {title: 'Rezystory'},
    };
    // alarmy
    alarms = {
        WTC_ALARM_BLAD_PAMIECY_NASTAW: {title: 'Błąd pamięci nastaw'},
        WTC_ALARM_BLAD_PAMIECY_PROGRAMU: {title: 'Błąd pamięci programu'},
        WTC_ALARM_AWARIA_CZUNIKA_T1: {title: 'Awaria czujnika T1'},
        WTC_ALARM_PRZEGRZANIE_T1: {title: 'Przegrzanie czujnika T1'},
        WTC_ALARM_AWARIA_CZUNIKA_T2: {title: 'Awaria czujnika T2'},
        WTC_ALARM_PRZEGRZANIE_T2: {title: 'Przegrzanie czujnika T2'},
        WTC_ALARM_WYSOKA_TEMP_RADIATORA: {title: 'Wysoka temp. radiatora'},
        WTC_ALARM_WYSOKA_TEMP_GENERATORA: {title: 'Wysoka temp. generatora'},
        WTC_ALARM_WYSOKIE_NAPIECIE_SYSTEMOWE: {title: 'Wysokie napięcie systemowe'},
        WTC_ALARM_NISKIE_NAPIECIE_SYSTEMOWE: {title: 'Niskie napięcie systemowe'},
        WTC_ALARM_WYSOKIE_NAPIECIE_DC: {title: 'Wysokie napięcie DC'},
        WTC_ALARM_BLAD_POMIARU_NAPIECA_AC: {title: 'Błąd pomiaru napięcia AC turbiny'},
        WTC_ALARM_ASYMETRIA_FAZ_NAPIECIE: {title: 'Alarm asymetria napięcia faz'},
        WTC_ALARM_ASYMETRIA_FAZ_PRAD: {title: 'Alarm asymetria prądu faz'},
        WTC_ALARM_WYSOKIE_NAPIECIE_INVERTER: {title: 'Alarm wysokie napięcie inwertera'},
        WTC_ALARM_BLAD_INVERTERA: {title: 'Alarm błąd inwertera'}
    };

    briefPanel = {
        name: BriefPanelName.ELEKTROMIZ_WIND_TURBINE_CONTROL_GONDOLA
    };

    dashBoard = {
        panelType: PanelTypeEnum.ELEKTROMIZ_WIND_TURBINE_CONTROL_GONDOLA
    };

    historyBoard = {
        title: 'Moc [W]',
        content: [
            {
                paramId: 'WTC_VAL_GRZALKA_MOC',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_TURBINA_ZUZYCIE_MOCY',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_INVERTER_MOC',
                unit: Unit.W,
                vAxisType: VAxisType.POWER_W.valueOf(),
            },
            {
                paramId: 'WTC_VAL_TURBINA_RPM',
                unit: Unit.RPM,
                vAxisType: VAxisType.RPM.valueOf(),
            },
            {
                paramId: 'WTC_VAL_GONDOLA_ROTACJA',
                unit: Unit.DEGREE,
                vAxisType: VAxisType.DEGREE.valueOf(),
            },
            {
                paramId: 'WTC_VAL_WIATR_KIERUNEK',
                unit: Unit.DEGREE,
                vAxisType: VAxisType.DEGREE.valueOf(),
            },
            {
                paramId: 'WTC_VAL_WIATR_PREDKOSC',
                unit: Unit.MS,
                vAxisType: VAxisType.MS.valueOf(),
            },
            {
                paramId: 'WTC_VAL_INVERTER_NAPIECIE_DC',
                unit: Unit.V,
                vAxisType: VAxisType.V.valueOf(),
            },
            {
                paramId: 'WTC_VAL_NAPIECIE_SYSTEMOWE',
                unit: Unit.V,
                vAxisType: VAxisType.V.valueOf(),
            },
            {
                paramId: 'WTC_VAL_TEMPERATURA_STEROWNIKA',
                unit: Unit.C,
                vAxisType: VAxisType.TEMP.valueOf(),
            },
            {
                paramId: 'WTC_VAL_NAPIECIE_AC',
                unit: Unit.C,
                vAxisType: VAxisType.TEMP.valueOf(),
            },
        ]
    };

    listBoard = {
        title: 'Konfiguracja',
        content: [
        {
            paramId: 'WERSJA_STEROWNIKA',
            unit: Unit.NONE,
            step: 0.01,
        },
        {
            title: 'Odczyty Mocy',
            type: 'MENU',
            content: [
                {
                    paramId: 'WTC_VAL_GRZALKA_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_TURBINA_MOC',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_TURBINA_ZUZYCIE_MOCY',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_MOC',
                    unit: Unit.W,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NASTAWA_MOC',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    unit: Unit.W,
                },
            ]},
                {
                    title: 'Odczyty DC',
                    type: 'MENU',
                    content: [
                {
                    paramId: 'WTC_VAL_NAPIECIE_DC_TURBINA',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_DC',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L1',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L2',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_INVERTER_NAPIECIE_L3',
                    unit: Unit.V,
                    step: 0.1,
                },
                ]},
                {
                    title: 'Odczyty AC',
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_VAL_NAPIECIE_AC',
                        unit: Unit.V,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_NAPIECIE_AC_L1',
                        unit: Unit.V,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_NAPIECIE_AC_L2',
                        unit: Unit.V,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_NAPIECIE_AC_L3',
                        unit: Unit.V,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_PRAD_AC_L1',
                        unit: Unit.A,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_PRAD_AC_L2',
                        unit: Unit.A,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_PRAD_AC_L3',
                        unit: Unit.A,
                        step:1,
                    },
                ]},
                {
                    title: 'Odczyty sieć',
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_MOC',
                        unit: Unit.W,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_ENERGIA_CALK',
                        unit: Unit.KWH,
                        step:0.001,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_NAPIECIE_L1',
                        unit: Unit.V,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_NAPIECIE_L2',
                        unit: Unit.V,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_NAPIECIE_L3',
                        unit: Unit.V,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_PRAD_L1',
                        unit: Unit.A,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_PRAD_L2',
                        unit: Unit.A,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIK_SIEC_PRAD_L3',
                        unit: Unit.A,
                        step:0.1,
                    },
                ]},
                {
                    title: 'Odczyty Temperatury',
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_VAL_TEMPERATURA_T1',
                        unit: Unit.C,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMPERATURA_T2',
                        unit: Unit.C,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMPERATURA_T3',
                        unit: Unit.C,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMPERATURA_STEROWNIKA',
                        unit: Unit.C,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMP_RADIATOR',
                        unit: Unit.C,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMP_GENERATOR',
                        unit: Unit.C,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_VAL_TEMP_GONDOLI',
                        unit: Unit.C,
                        step: 0.1,
                    },
                ]},
                {
                    title: 'Odczyty wiatr',
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'WTC_VAL_WIATR_PREDKOSC',
                            unit: Unit.MS,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_VAL_WIATR_KIERUNEK',
                            unit: Unit.DEGREE,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_VAL_CISNIENIE_ATMOSFERYCZNE',
                            unit: Unit.HPA,
                            step: 0.1,
                        },
                        {
                            title: 'Historia wiatru',
                            type: 'MENU',
                            content: this.CreateWindHistory()
                        },
                ]},
                {
                    title: 'Dane historyczne',
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_MOCY',
                        unit: Unit.W,
                        step:1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_MOC_CALKOWITA',
                        unit: Unit.KWH,
                        step:0.001,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_MOC_CALKOWITA_REZYSTOR',
                        unit: Unit.KWH,
                        step:0.001,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_MOC_CALKOWITA_INWERTER',
                        unit: Unit.KWH,
                        step:0.001,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_MOC_CALKOWITA_BATERIA',
                        unit: Unit.KWH,
                        step:0.001,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_WIATR',
                        step:0.01,
                        unit: Unit.MS,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_NAPIECIE_AC',
                        unit: Unit.V,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_PRAD_AC',
                        unit: Unit.A,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_NAPIECIE_DC',
                        unit: Unit.V,
                        step:0.1,
                    },
                    {
                        paramId: 'WTC_VAL_LICZNIKI_PEAK_OBROTY',
                        unit: Unit.RPM,
                    },
                ]},
                {
                    paramId: 'WTC_VAL_STAN_STEROWNIKA',
                    type: 'ITEM-SELECT',
                    options: [{value: '0', name: 'Błąd'}, {value: '1', name: 'Praca'}, {value: '2', name: 'Zatrzymanie ręczne'}, {value: '3', name: 'Zatrzymanie automatyczne'}]
                },
                {
                    paramId: 'WTC_VAL_GRZALKA_PWM',
                    unit: Unit.PERCENTAGE,
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                },
                {
                    paramId: 'WTC_VAL_TURBINA_RPM',
                    unit: Unit.RPM,
                },
                {
                    paramId: 'WTC_VAL_GONDOLA_ROTACJA',
                    unit: Unit.DEGREE,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_NAPIECIE_SYSTEMOWE',
                    unit: Unit.V,
                    step: 0.1,
                },
                {
                    paramId: 'WTC_VAL_AKCELEROMETR_MAX_G',
                    unit: Unit.G,
                    step:1,
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                },
                {
                    paramId: 'WTC_VAL_MAGNETOMETER_KIERUNEK',
                    unit: Unit.DEGREE,
                    step:1,
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                },
                {
                    paramId: 'WTC_SETP_STAN_PRACY_TURBINY',
                    type: 'ITEM-SELECT',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    options: [{value: '1', name: 'Praca'}, {value: '2', name: 'Zatrzymanie ręczne'}]
                },
                {
                    title: 'Nastawy napięcie systemowe',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_SETP_NAPIECIE_SYSTEMOWE',
                        type: 'ITEM-SELECT',
                        options: [{value: '0', name: '24V'}, {value: '1', name: '48V'}, {value: '2', name: '400V'}]
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_24V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_48V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_MAX_400V',
                        unit: Unit.V,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_NAPIECIE_SYSTEMOWE_KOREKTA',
                        unit: Unit.V,
                        step: 0.1,
                    },
                ]},
                {
                    title: 'Nastawy hamowanie',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_CZAS',
                        unit: Unit.SEC,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_DYNAMICZNE_CZAS_SREDNIA',
                        unit: Unit.SEC,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_DYNAMICZNE_SILA',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_IMPULSOWE_MOC_STARTU',
                        unit: Unit.W,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_IMPULSOWE_CZAS_IMPULS',
                        unit: Unit.SEC,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_IMPULSOWE_CZAS_PAUZA',
                        unit: Unit.SEC,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_IMPULSOWE_SILA',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_TEMPERATUROWE_AKTYWNE',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_TEMPERATUROWE_TEMP_STOP',
                        unit: Unit.C,
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_TEMPERATUROWE_TEMP_START',
                        unit: Unit.C,
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_PRZECIAZENIE_AKTYWNE',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_PRZECIAZENIE_CZAS_PRZECIAZENIA',
                        unit: Unit.SEC,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_ZATRZYMANIE_PRZECIAZENIE_CZAS_ZATRZYMANIA',
                        unit: Unit.MIN,
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_FAZOWE_AKTYWNE',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_FAZOWE_NAPIECIE',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_NOMINALNE_NAPIECIE_PRZECIAZENIE',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_TYP_HAMOWANIA',
                        type: 'ITEM-SELECT',
                        options: [{value: '0', name: 'SSR AC'}, {value: '1', name: 'PWM DC'}]
                    },
                    {
                        paramId: 'WTC_SETP_HAMOWANIE_PREDKOSCI_WIATRU',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_MAX_PREDKOSC_WIATRU',
                        unit: Unit.MS,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_CZAS_MAX_PREDKOSC_WIATRU',
                        unit: Unit.SEC,
                        step: 1,
                    },
                    {
                        paramId: 'WTC_SETP_MAX_PREDKOSC_WIATRU_CZAS_ZATRZYMANIA',
                        unit: Unit.SEC,
                        step: 1,
                    },
                    {
                        paramId: 'WTC_SETP_MIN_PREDKOSC_WIATRU_STOP',
                        unit: Unit.MS,
                        step: 0.1,
                    },
                    {
                        paramId: 'WTC_SETP_MIN_PREDKOSC_WIATRU_START',
                        unit: Unit.MS,
                        step: 0.1,
                    },
                ]},
                {
                    paramId: 'WTC_SETP_OBROT_CZAS',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    unit: Unit.SEC,
                    step: 0.1,
                },
                {
                    title: 'Ustawienia inwerter',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_SETP_INVERTER_CZAS_POLACZENIA',
                        unit: Unit.SEC,
                    },
                    {
                        paramId: 'WTC_SETP_INVERTER_MAX_MOC',
                        unit: Unit.KW,
                    },
                    {
                        paramId: 'WTC_SETP_INVERTER_MOC_PROPORCJONALNA',
                        unit: Unit.PERCENTAGE,
                    },
                    {
                        paramId: 'WTC_SETP_TYP_INWERTERA',
                        type: 'ITEM-SELECT',
                        options: [{value: '0', name: 'Stringowy'}, {value: '1', name: 'Hybryda'}]
                    },
                    {
                        paramId: 'WTC_SETP_PRODUCENT_INWERTERA',
                        type: 'ITEM-SELECT',
                        options: [{value: '0', name: 'Deye'}, {value: '1', name: 'Growatt'}]
                    },
                ]},
                {
                    paramId: 'WTC_SETP_TURBINA_POLA_MAGNETYCZNE',
                    unit: Unit.NONE,
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                },
                {
                    paramId: 'WTC_SETP_GRZALKA_PUNKT_STARTOWY',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    unit: Unit.NONE,
                },
                {
                    paramId: 'WTC_SETP_MAX_PRZECIAZENIE_TURBINY',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    unit: Unit.PERCENTAGE,
                },
                {
                    paramId: 'WTC_SETP_WYBRANA_KRZYWA_MOCY',
                    type: 'ITEM-SELECT',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    options: [{value: '0', name: 'RPM'}, {value: '1', name: 'AC'}]
                },
                {
                    paramId: 'WTC_SETP_NOMINALNE_NAPIECIE',
                    unit: Unit.V,
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                },
                {
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    paramId: 'WTC_SETP_JEZYK',
                    type: 'ITEM-SELECT',
                    options: [{value: '0', name: 'Polski'}, {value: '1', name: 'Niemiecki'}]
                },
                {
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    paramId: 'WTC_SETP_RODZAJ_TURBINY',
                    type: 'ITEM-SELECT',
                    options: [{value: '0', name: 'Pozioma'}, {value: '1', name: 'Pozioma'}]
                },
                {
                    title: 'Sterowanie ręczne',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                    {
                        paramId: 'WTC_SETP_STEROWANIE_RECZNE_START',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_STEROWANIE_RECZNE_GONDOLA_LEWO',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },
                    {
                        paramId: 'WTC_SETP_STEROWANIE_RECZNE_GONDOLA_PRAWO',
                        type: 'ITEM-SWITCH',
                        options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                    },],
                },
                {
                    title: 'Krzywa mocy rpm',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: this.CreatePowerCurveRpm()
                },
                {
                    title: 'Krzywa mocy AC',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: this.CreatePowerCurveAc()
                },
                {
                    title: 'Krzywa ładowania akumulatora',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: this.CreateVoltageCurve()
                },
                {
                    title: 'Przekaźniki',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                        {
                            title: 'RL 1',
                            type: 'MENU',
                            content: this.CreateRelaySettings(1),
                        },
                        {
                            title: 'RL 2',
                            type: 'MENU',
                            content: this.CreateRelaySettings(2),
                        },
                        {
                            title: 'RL 3',
                            type: 'MENU',
                            content: this.CreateRelaySettings(3),
                        },
                        {
                            title: 'RL 4',
                            type: 'MENU',
                            content: this.CreateRelaySettings(4),
                        },
                        {
                            title: 'RL 5',
                            type: 'MENU',
                            content: this.CreateRelaySettings(5),
                        },
                    ],
                },
                {
                    title: 'Wyświetlacz',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'WTC_SETP_JASNOSC_EKRANU',
                            unit: Unit.PERCENTAGE,
                            step: 1,
                        },
                        {
                            paramId: 'WTC_SETP_WYGASZACZ_EKRANU',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_WYGASZACZ_CZAS',
                            unit: Unit.MIN,
                            step: 1,
                        },
                    ],
                },
                {
                    title: 'ALARMY',
                    readPermission: AccessPermissionEnum.INSTALLER.valueOf(),
                    type: 'MENU',
                    content: [
                        {
                            paramId: 'WTC_SETP_AKTYWNE_ALARMY_ODCZYT_DC',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_AKTYWNE_ALARMY_ASYMERIA_FAZ_NAPIECIE',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_ASYMETRIA_FAZ_NAPIECIE',
                            unit: Unit.PERCENTAGE,
                            step: 1,
                        },
                        {
                            paramId: 'WTC_SETP_AKTYWNE_ALARMY_ASYMERIA_FAZ_PRAD',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_ASYMETRIA_FAZ_PRAD',
                            unit: Unit.PERCENTAGE,
                            step: 1,
                        },
                        {
                            paramId: 'WTC_SETP_AKTYWNE_ALARMY_TEMP_RADIATOR',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_TEMPERATURA_RADIATORA',
                            unit: Unit.C,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_SETP_AKTYWNE_ALARMY_TEMP_GENERATOR',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_TEMPERATURA_GENERATORA',
                            unit: Unit.C,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_MAX_NAPIECIE_INVERTER',
                            unit: Unit.V,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_SETP_PROG_ALARM_NISKIE_NAPIECIE_SYSTEMOWE',
                            unit: Unit.V,
                            step: 0.1,
                        },
                        {
                            paramId: 'WTC_SETP_KASOWANIE_ALARMOW',
                            type: 'ITEM-SWITCH',
                            options: [{value: '0', name: 'Nie'}, {value: '1', name: 'Tak'}]
                        },
                    ],
                },
    ]};

    scheduleBoard = null;

    CreateWindHistory()
    {
        const content = []
        const points = 21
        for(let i = 0; i < points; i++)
        {
            const pointTab =
            {
                paramId: 'WTC_VAL_HISTORIA_WIATRU_P'+i,
                unit: Unit.SEC,
            }
            content.push(pointTab)
        }
        return content
    }

    CreatePowerCurveRpm()
    {
        const content = []
        const powerPointsAmount = 12
        for(let i = 1; i <= powerPointsAmount; i++)
        {
            const rpmPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_RPM_RPM_P'+i,
                unit: Unit.RPM,
            }
            content.push(rpmPointDesc)
            const powerPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_RPM_W_P'+i,
                unit: Unit.W,
            }
            content.push(powerPointDesc)
        }
        return content
    }

    CreatePowerCurveAc()
    {
        const content = []
        const powerPointsAmount = 12
        for(let i = 1; i <= powerPointsAmount; i++)
        {
            const rpmPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_AC_V_P'+i,
                unit: Unit.V,
                step: 0.1,
            }
            content.push(rpmPointDesc)
            const powerPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_MOCY_AC_W_P'+i,
                unit: Unit.W,
            }
            content.push(powerPointDesc)
        }
        return content
    }

    CreateVoltageCurve()
    {
        const content = []
        const voltagePointsAmount = 6
        for(let i = 1; i <= voltagePointsAmount; i++)
        {
            const rpmPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_NAPIECIA_V_P'+i,
                unit: Unit.V,
                step: 0.1,
            }
            content.push(rpmPointDesc)
            const powerPointDesc =
            {
                paramId: 'WTC_SETP_KRZYWA_NAPIECIA_A_P'+i,
                unit: Unit.A,
                step: 0.1,
            }
            content.push(powerPointDesc)
        }
        return content
    }

    CreateCommonRelaySettings(relayId, content)
    {
        const d2 =
        {
            paramId: 'WTC_SETP_PRZEKAZNIK_STEROWANIE_RL' + relayId,
            type: 'ITEM-SELECT',
            options: [
                {value: '0', name: 'Temperatura T1'},
                {value: '1', name: 'Temperatura T2'},
                {value: '2', name: 'Napięcie AC'},
                {value: '3', name: 'Napięcie DC'}]
        }
        content.push(d2)
        const d3 =
        {
            paramId: 'WTC_SETP_PRZEKAZNIK_TEMP_WLACZENIA_RL' + relayId,
            unit: Unit.C,
            step: 0.1,
        }
        content.push(d3)
        const d4 =
        {
            paramId: 'WTC_SETP_PRZEKAZNIK_TEMP_WYLACZENIA_RL' + relayId,
            unit: Unit.C,
            step: 0.1,
        }
        content.push(d4)
        const d5 =
        {
            paramId: 'WTC_SETP_PRZEKAZNIK_V_WLACZENIA_RL' + relayId,
            unit: Unit.V,
            step: 0.1,
        }
        content.push(d5)
        const d6 =
        {
            paramId: 'WTC_SETP_PRZEKAZNIK_V_WYLACZENIA_RL' + relayId,
            unit: Unit.V,
            step: 0.1,
        }
        content.push(d6)
    }
    CreateRelaySettings(relayId)
    {
        const content = []
        switch(relayId)
        {
            case 1:
            case 2:
            {
                const d1 =
                {
                    paramId: 'WTC_SETP_PRZEKAZNIK_FUNKCJA_RL' + relayId,
                    type: 'ITEM-SELECT',
                    options: [{value: '1', name: 'Gondola'}, {value: '2', name: 'Użytkownik'}]
                }
                content.push(d1)
            }break;
            case 3:
            case 4:
            {
                const d1 =
                {
                    paramId: 'WTC_SETP_PRZEKAZNIK_FUNKCJA_RL' + relayId,
                    type: 'ITEM-SELECT',
                    options: [{value: '3', name: 'Hamowanie'}, {value: '2', name: 'Użytkownik'}]
                }
                content.push(d1)
            }break;
            case 5:
            {
                const d1 =
                {
                    paramId: 'WTC_SETP_PRZEKAZNIK_FUNKCJA_RL' + relayId,
                    type: 'ITEM-SELECT',
                    options: [{value: '2', name: 'Użytkownik'}]
                }
                content.push(d1)
            }break;
        }
        this.CreateCommonRelaySettings(relayId, content)
        return content
    }
}
