export enum Unit {
    'NONE' = '',
    'C' = '°C',
    'PERCENTAGE' = '%',
    'HPA' = 'hPa',
    'KPA' = 'kPa',
    'DEGREE' = '°',
    'HOUR' = 'h',
    'MIN' = 'min',
    'SEC' = 'sec',
    'KWH' = 'kWh',
    'KW' = 'kW',
    'ATM' = 'atm',
    'DAY' = 'd',
    'BAR' = 'bar',
    'PPM' = 'ppm',
    'KGH' = 'kg/h',
    'KG' = 'kg',
    'M3H' = 'm3/h',
    'LUX' = 'lux',
    'RPM' = 'rpm',
    'HZ' = 'Hz',
    'N' = 'N',
    'A' = 'A',
    'V' = 'V',
    'W' = 'W',
    'MS' = 'm/s',
    'G' = 'g',
}
